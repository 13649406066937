import {
  activateVersionRefreshBanner,
  isNewTheme,
  isProd,
} from "core/model/utils/featureFlags";
import { getSealdSDKInstance } from "core/seald";
import { Banner } from "ds/ui";
import { InfoBanner } from "ds_legacy/components/InfoBanner";
import { Z_INDEX_MAX, dp, sizing } from "ds_legacy/materials/metrics";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useEffect, useState } from "react";
import { useOnLogout } from "reduxentities/actions";
import { useLoggedInAccount } from "reduxentities/selectors";
import { useTranslations } from "translations";
import { usePrint } from "../Contexts";

export function RefreshBanner({ onClick }: { onClick?: AnyFunction }) {
  const translations = useTranslations();
  const { isMobile } = useMedia();
  const print = usePrint();

  if (print) return null;

  return (
    <div
      data-testid="refreshBanner"
      style={{
        position: "fixed",
        left: "50%",
        top: sizing(6.5),
        width: sizing(isMobile ? 50 : 64),
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      {isNewTheme() ? (
        <div className="mt-2 w-full">
          <Banner
            message={translations.general.refreshToSeeChanges}
            color="warning"
            actions={[
              {
                id: "refresh",
                label: translations.actions.refresh,
                onClick: onClick ? onClick : () => window.location.reload(),
              },
            ]}
          />
        </div>
      ) : (
        <InfoBanner
          message={translations.general.refreshToSeeChanges}
          severity="warning"
          wrapperStyle={{ width: "100%", marginTop: sizing(1) }}
          actions={[
            {
              label: translations.actions.refresh,
              onClick: onClick ? onClick : () => window.location.reload(),
            },
          ]}
        />
      )}
    </div>
  );
}

export function RefreshBannerVersionChecker({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const translations = useTranslations();
  const print = usePrint();

  if (print) return null;

  if (!activateVersionRefreshBanner) {
    return null;
  }

  return (
    <div
      style={{
        bottom: dp(20),
        left: "50%",
        position: "fixed",
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      {isNewTheme() ? (
        <div className="mt-2 w-full">
          <Banner
            message={translations.todo.refreshApp}
            color="primary"
            actions={[
              {
                id: "refresh",
                label: translations.actions.refresh,
                onClick: () => window.location.reload(),
              },
              ...(!isProd
                ? [
                    {
                      id: "dismiss",
                      label: translations.actions.dismiss,
                      onClick: onDismiss,
                    },
                  ]
                : []),
            ]}
          />
        </div>
      ) : (
        <InfoBanner
          message={translations.todo.refreshApp}
          severity="info"
          highContrast
          wrapperStyle={{ width: "100%", marginTop: sizing(1) }}
          actions={[
            {
              label: translations.actions.refresh,
              onClick: () => window.location.reload(),
            },
            ...(!isProd
              ? [
                  {
                    label: translations.actions.dismiss,
                    onClick: onDismiss,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
}

export function RefreshBannerSealdSDK() {
  const translations = useTranslations();
  const onLogout = useOnLogout();
  const account = useLoggedInAccount();
  const [sealdSDKAccess, setSealdSDKAccess] = useState(true);

  useEffect(() => {
    if (account && sealdSDKAccess) {
      getSealdSDKInstance().catch(() => {
        console.error(`Seald SDK refresh banner for account ${account.id}`);
        setSealdSDKAccess(false);
      });
    }
  }, [account, sealdSDKAccess]);

  if (sealdSDKAccess) return null;

  return (
    <div
      style={{
        top: sizing(6.5),
        left: "50%",
        position: "fixed",
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      {isNewTheme() ? (
        <div className="mt-2 w-full">
          <Banner
            message={translations.general.banner.cta.noEncryptionAccessAlert}
            heading={
              translations.general.banner.cta.noEncryptionAccessAlertTitle
            }
            color="danger"
            actions={[
              {
                id: "logout",
                label:
                  translations.general.banner.cta.noEncryptionAccessAlertAction,
                onClick: () => {
                  setSealdSDKAccess(true);
                  onLogout({ hardRefresh: true });
                },
              },
            ]}
          />
        </div>
      ) : (
        <InfoBanner
          message={translations.general.banner.cta.noEncryptionAccessAlert}
          header={translations.general.banner.cta.noEncryptionAccessAlertTitle}
          severity="error"
          wrapperStyle={{ width: "100%", marginTop: sizing(1) }}
          actions={[
            {
              label:
                translations.general.banner.cta.noEncryptionAccessAlertAction,
              onClick: () => {
                setSealdSDKAccess(true);
                onLogout({ hardRefresh: true });
              },
            },
          ]}
        />
      )}
    </div>
  );
}
